<template>
    <div class="report_box">
        <h3>举报</h3>
        <template v-for="(item, index) in list">
            <div class="list" @click="jumpSunmit(index)">{{ item }}</div>
        </template>

        <div class="footer">
            <p>更多问题发邮件至</p>
            <p>service@jisuchou.net</p>
        </div>

    </div>
</template>
<script>
import utils from "@/common/js/utils";
import { shareProj } from '@/common/js/mmcModule';
import { authChecker } from '@/common/js/mmcModule';
const params = utils.getRequestParams();
export default {
    data() {
        return {
            projuuid: '',
            list: [
                '存在欺诈骗钱行为',
                '存在违法行为',
                '存在侵权行为',
                '其他问题'
            ]
        }
    },
    mounted() {
        const { projuuid } = params;
        this.projuuid = projuuid;
        const that = this;
        authChecker.check({ notneedphone: 1 },() => {
            that.initShare();
        });
    },
    methods: {
        initShare() {
            const { projuuid } = params;
            const shareParams = {
                projuuid
            };
            shareProj.init(shareParams);
        },
        jumpSunmit(e) {
            this.$router.push({
                path: `/fund/project/report-form`,
                query: {
                    projuuid: this.$route.query.projuuid,
                    item: e,
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
.report_box {
    padding: 35px 20px;
    h3 {
        font-size: 34px;
        text-align: left;
        margin-bottom: 26px;
    }
    .list {
        position: relative;
        font-size: 16px;
        text-align: left;
        margin: 27px 0;
        font-weight: bold;
        &:after{
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 1px solid #333 ;
            border-right: 1px solid #333 ;
            transform: rotate(45deg) translateY(-50%);
            position: absolute;
            right: 5px;
            top: 50%;
        }
    }
    .footer {
        width: 100%;
        color: #ccc;
        position: fixed;
        bottom: 30px;
        left: 0;
        text-align: center;
    }
}
</style>
